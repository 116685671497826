const ERROR_FIELDS = ["error", "errorMessage"];
export class NetworkError extends Error {
    response;
    responseData;
    constructor(message, options) {
        super(message);
        this.response = options.response;
        this.responseData = options.responseData;
    }
}
export async function fetchWithError(input, init) {
    const response = await fetch(input, init);
    if (!response.ok) {
        const responseData = await parseResponse(response);
        const message = getErrorMessage(responseData);
        throw new NetworkError(message, {
            response,
            responseData,
        });
    }
    return response;
}
export async function parseResponse(response) {
    if (!response.body) {
        return "";
    }
    const data = await response.text();
    try {
        return JSON.parse(data);
        // eslint-disable-next-line no-empty
    }
    catch (error) { }
    return data;
}
function getErrorMessage(data) {
    if (typeof data !== "object" || data === null) {
        return "Unable to determine error message.";
    }
    for (const key of ERROR_FIELDS) {
        const value = data[key];
        if (typeof value === "string") {
            return value;
        }
    }
    return "Network response was not OK.";
}
